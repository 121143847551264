import Vue from 'vue'
import App from './App.vue'
// 引入router 实例
import router from '../src/router/index'
// 引入 elementui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// 注册全局组件
import Footer from '@/component/Footer.vue'
import Header from '@/component/Header.vue'
Vue.component('Footer', Footer)
Vue.component('Header', Header)
// 百度地图
import BaiduMap from 'vue-baidu-map'
// aos滚动动画
import AOS from "aos";
import "../node_modules/aos/dist/aos.css"
Vue.use(AOS)
// 自适应引入
// import 'lib-flexible'

// import '@/util/rem'
import "amfe-flexible/index.js";



Vue.config.productionTip = false
Vue.use(BaiduMap, { ak: 'A3nFZem8HT038tbgGzrqskxLfnWs4bsv' })
new Vue({
  render: h => h(App),
  router,
  beforeCreate() {
    Vue.prototype.$bus = this
  }
}).$mount('#app')
