<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  watch: {
    $router(to, from) {
      console.log(to.path);
    },
  },
};
</script>

<style >
* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  width: 100%;
  /* 禁止拖动鼠标文字 */
  user-select: none;
}
* {
  margin: 0;
  padding: 0;
  list-style: none;
  background-repeat: no-repeat;
}
</style>
