<template>
  <div id="shoot">
    <!-- Footer -->
    <div class="footer">
      <!-- 公司简介 -->
      <div class="componentDetail">
        <img src="https://newstore.vynior.com/z%20935%402x.png" alt="" />
        <span class="Text">上海肤焕科技有限公司</span>
        <span class="Text2"
          >靠 谱 &emsp; | &emsp;专 注 &emsp;| &emsp;颠 覆</span
        >
      </div>
      <!-- 分割线 -->
      <div class="line"></div>
      <!-- 中间图片 -->
      <div class="QRcode">
        <div class="code">
          <span>关注我们</span>
          <img src="https://newstore.vynior.com/picture66%402x.png" alt="" />
        </div>

        <div class="maps">
          <span>点击放大</span>

          <!-- <img src="https://newstore.vynior.com/pic%2051%402x.png" alt="" /> -->

          <baidu-map
            id="map1"
            @ready="mapHandle"
            @click="enlarge"
            ref="showPanel"
            :scroll-wheel-zoom="true"
          ></baidu-map>
        </div>
      </div>
      <!-- 分割线2 -->
      <div class="line2"></div>
      <!-- 右边信息 -->
      <div class="RightDetail">
        <div class="first">
          <img src="https://newstore.vynior.com/di_dizhi%402x.png" />
          <div class="Text">
            <span>地址：上海市浦东新区纳贤路800号科海大楼A座3楼</span>
          </div>
        </div>
        <div class="second">
          <img src="https://newstore.vynior.com/di_dizhi%402x%281%29.png" />

          <div class="Text"><span>电话：400-616-0722</span></div>
        </div>
        <div class="end">
          <img src="https://newstore.vynior.com/di_dizhi%402x%282%29.png" />
          <div class="Text"><span>邮箱：contact@fuhuan.ltd</span></div>
        </div>
      </div>
    </div>
    <!-- 底部备案号 -->
    <div class="record">
      <div class="Text">
        <span>ICP证：沪ICP备18035884号-5</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {
    // map地图设置
    mapHandle({ BMap, map }) {
      // var map = new BMap.Map("map1"); // 创建Map实例
      var point = new BMap.Point(121.61189, 31.184336);
      // map.centerAndZoom(point, 13)
      map.centerAndZoom(point, 12);
      // 创建地址解析器实例
      var myGeo = new BMap.Geocoder();
      // 将地址解析结果显示在地图上，并调整地图视野

      myGeo.getPoint(
        "科海大楼",
        function (point) {
          if (point) {
            map.centerAndZoom(point, 16);
            map.addOverlay(
              new BMap.Marker(point, {
                title: "上海",
              })
            );
          } else {
            console.log("您选择的地址没有解析到结果！");
          }
        },
        "全国"
      );
    },
    //点击放大
    enlarge() {
      let box = document.getElementById("map1");
      box.id = "enlarge";
    },
  },
  created() {
    //点击的不是地图，地图关闭
    document.addEventListener("click", (e) => {
      const isSelf = document.getElementById("enlarge");
      if (isSelf) {
        // let isSelf = this.$refs.showPanel.contains(e.target);
        if (!isSelf.contains(e.target)) {
          let box = document.getElementById("enlarge");
          box.id = "map1";
          console.log("1");
        }
      }
    });
  },
};
</script>

<style lang="less">
@import url("../style/Footer.less");
</style>