// 引入路由模块 挂载使用哦
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [{
    path: '/',
    component: () => import('../views/Home.vue'),


},
{
    path: '/standard',
    name: 'standard',
    component: () => import('../views/Standard.vue'),
},
{
    path: '/professional',
    name: 'professional',
    component: () => import('../views/Professional.vue'),



},
{
    path: '/contactus',
    name: 'contactus',
    component: () => import('../views/ContactUs.vue'),

},
{
    path: '/companyprofile',
    name: 'companyprofile',
    component: () => import('../views/companyProfile.vue'),

},
{
    path: '/swiper',
    component: () => import('../views/swiperqqq.vue'),

},

]




const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    // scrollBehavior: () => ({ y: 0 }),//解决 跳转后不是顶部的问题

    routes
})
export default router