<template>
  <!-- 头部导航栏 -->
  <div id="headers">
    <div class="header">
      <div class="header_logo">
        <img
          src="https://newstore.vynior.com/%E8%82%A4%E7%84%95LOGO-03%402x.png"
          alt=""
        />
      </div>
      <div class="header_text">上海肤焕科技有限公司</div>
      <!-- ui -->
      <div class="header_Nav">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1" @click="goHome">首页</el-menu-item>
          <el-submenu index="2" class="productCenter">
            <template slot="title">产品中心</template>
            <el-menu-item class="product" index="2-1" @click="goStandard">
              <div>
                <div class="product-fist-img">
                  <img src="https://newstore.vynior.com/vplusbzb%402x.png" />
                </div>
                <div class="product-fist-vplus">
                  <img src="https://newstore.vynior.com/z109216%402x.png" />
                </div>
                <div class="product-fist-Text">
                  <span>智能皮肤分析系统</span>
                </div>
              </div>
            </el-menu-item>
            <el-menu-item class="product2" index="2-2" @click="goProfessional">
              <div>
                <div class="product-two-img">
                  <img src="https://newstore.vynior.com/vpluszyb%402x.png" />
                </div>
                <div class="product-two-vplus">
                  <img src="https://newstore.vynior.com/z109219%402x.png" />
                </div>
                <div class="product-two-Text" style="text-align: center">
                  <span>智能皮肤分析系统</span>
                </div>
              </div>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">企业介绍</template>
            <el-menu-item index="3-1" @click="goCompany">肤焕简介</el-menu-item>
            <el-menu-item index="3-2" @click="goHistory">发展历程</el-menu-item>
            <!-- <el-menu-item index="3-3">企业文化</el-menu-item> -->
            <el-menu-item index="3-4" @click="goCore">核心优势</el-menu-item>
            <el-menu-item index="3-5" @click="goBrand">合作品牌</el-menu-item>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">联系我们</template>
            <el-menu-item index="4-1" @click="goContackUs"
              >联系我们</el-menu-item
            >
            <el-menu-item index="4-2" @click="goTalk">商务洽谈</el-menu-item>
          </el-submenu>
          <!-- <el-menu-item index="5" @click="goText">中/En</el-menu-item> -->
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      activeIndex: "1",
    };
  },

  methods: {
    // 导航设置
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    // 路由跳转
    goStandard() {
      this.$router.push({ path: "/standard" });
    },
    goProfessional() {
      this.$router.push({ path: "/professional" });
    },
    goContackUs() {
      this.$router.push({ path: "/contactus" });
      this.$bus.$emit("events", { data: "TalkUS" });
    },
    goTalk() {
      this.$router.push({ name: "contactus", params: { mes: "Talk" } });
      this.$bus.$emit("events", { data: "Talk" });
    },
    goHome() {
      this.$router.push({ path: "/" });
    },
    goText() {
      this.$router.push({ path: "/swiper" });
    },
    goCompany() {
      this.$router.push({
        name: "companyprofile",
        params: { mes: "fh-detail" },
      });
      this.$bus.$emit("event", { data: "fh-detail" });
    },
    goHistory() {
      this.$router.push({
        name: "companyprofile",
        params: { mes: "fh-history" },
      });
      this.$bus.$emit("event", { data: "fh-history" });
    },
    goCore() {
      this.$router.push({
        name: "companyprofile",
        params: { mes: "fh-core" },
      });
      this.$bus.$emit("event", { data: "fh-core" });
    },
    goBrand() {
      this.$router.push({
        name: "companyprofile",
        params: { mes: "fh-brand" },
      });
      this.$bus.$emit("event", { data: "fh-brand" });
    },
  },
  created() {
    console.log(this.$route.path);
    if (this.$route.path == "/standard") {
      this.activeIndex = "2-1";
    } else if (this.$route.path == "/professional") {
      this.activeIndex = "2-2";
    } else if (this.$route.path == "/contactus") {
      this.activeIndex = "4-1";
    } else if (this.$route.path == "/companyprofile") {
      this.activeIndex = "3-1";
    }
  },
  mounted() {},
};
</script>

<style lang="less" >
@import url("../style/Header.less");
// 隐藏滚动条
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
</style>